<template>
  <a-spin tip="加载中..." :spinning="isSpinning">
    <a-form :form="form">
      <a-form-item
        label="发布时间"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <a-date-picker
          placeholder="请输入发布时间"
          show-time
          v-decorator="[
            'createAt',
            {
              initialValue: record.createAt ? moment(record.createAt) : null,
            },
          ]"
        />
      </a-form-item>
      <a-form-item
        label="发布标题"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <a-input
          placeholder="请输入发布标题"
          v-decorator="[
            'name',
            {
              initialValue: record.name,
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="内容" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-textarea
          placeholder="请输入更新内容"
          :auto-size="{ minRows: 10, maxRows: 16 }"
          v-decorator="['content', { initialValue: record.content }]"
        />
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
import moment from "moment";
import { addHelp, editHelp } from "@/api/help";

export default {
  name: "TaskForm",
  props: {
    record: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 },
      },
      form: this.$form.createForm(this),
      isSpinning: false,
    };
  },
  methods: {
    moment,
    onOk() {
      return new Promise((resolve) => {
        this.form.validateFields((err, values) => {
          if (!err) {
            this.isSpinning = true;
            values.createAt = values.createAt
              ? moment(values.createAt).utc(480)
              : "";
            if (this.record.id === -1) {
              addHelp(values).then((res) => {
                this.isSpinning = false;
                resolve(res);
              });
            } else {
              let formData = {
                id: this.record.id,
                ...values,
              };
              editHelp(formData)
                .then((res) => {
                  resolve(res);
                })
                .finally(() => {
                  this.isSpinning = false;
                });
            }
          }
        });
      });
    },
    onCancel() {
      return new Promise((resolve) => {
        resolve(true);
      });
    },
  },
};
</script>
