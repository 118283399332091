var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"tip":"加载中...","spinning":_vm.isSpinning}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"发布时间","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'createAt',
          {
            initialValue: _vm.record.createAt ? _vm.moment(_vm.record.createAt) : null,
          } ]),expression:"[\n          'createAt',\n          {\n            initialValue: record.createAt ? moment(record.createAt) : null,\n          },\n        ]"}],attrs:{"placeholder":"请输入发布时间","show-time":""}})],1),_c('a-form-item',{attrs:{"label":"发布标题","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            initialValue: _vm.record.name,
          } ]),expression:"[\n          'name',\n          {\n            initialValue: record.name,\n          },\n        ]"}],attrs:{"placeholder":"请输入发布标题"}})],1),_c('a-form-item',{attrs:{"label":"内容","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['content', { initialValue: _vm.record.content }]),expression:"['content', { initialValue: record.content }]"}],attrs:{"placeholder":"请输入更新内容","auto-size":{ minRows: 10, maxRows: 16 }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }