import request from '@/utils/request'

// 更新说明
const helpApi = {
  GetHelpList: '/help/list',
  AddHelp: '/help/add',
  EditHelp: '/help/edit',
  DeleteHelp: '/help/del',
}

export function getHelpList(parameter) {
  return request({
    url: helpApi.GetHelpList,
    method: 'get',
    params: parameter
  })
}

export function addHelp(parameter) {
  return request({
    url: helpApi.AddHelp,
    method: 'post',
    data: parameter
  })
}

export function editHelp(parameter) {
  return request({
    url: helpApi.EditHelp,
    method: 'post',
    data: parameter
  })
}

export function deleteHelp(parameter) {
  return request({
    url: helpApi.DeleteHelp + `/${parameter.id}`,
    method: 'post',
  })
}