<template>
  <div class="list-box">
    <a-button
      type="primary"
      icon="plus"
      @click="handleEdit()"
      v-if="hasAction('add-help')"
      >新增更新说明</a-button
    >
    <a-table
      :columns="table.columns"
      :data-source="dataSource"
      :loading="table.loading"
      rowKey="id"
      :pagination="{ pageSize: 10 }"
    >
      <template slot="dataManage" slot-scope="text, record">
        <div>
          <a @click="handleEdit(record)" v-if="hasAction('edit-help')"
            >编辑修改</a
          >
          <a-divider
            type="vertical"
            v-if="hasAction('edit-help') && hasAction('delete-help')"
          />
          <a-popconfirm
            title="是否确定要删除此条更新说明？"
            ok-text="是"
            cancel-text="否"
            @confirm="handleDelete(record)"
            v-if="hasAction('delete-help')"
          >
            <a>删除</a>
          </a-popconfirm>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { getHelpList, deleteHelp } from "@/api/help";
import UpdateForm from "./components/UpdateForm";

export default {
  data() {
    return {
      dataSource: [],
      ui: {
        adding: false,
      },
      table: {
        loading: false,
        columns: [
          {
            title: "发布时间",
            width: "8em",
            dataIndex: "createAt",
            sorter: (row1, row2) => {
              return new Date(row1.createAt) - new Date(row2.createAt);
            },
            customRender: (text) => {
              return text.substr(0, 10);
            },
          },
          {
            title: "发布标题",
            width: "16em",
            dataIndex: "name",
          },
          {
            title: "内容",
            dataIndex: "content",
            ellipsis: true,
            customRender: (text) => {
              return (
                <a-tooltip
                  title={text}
                  placement="topLeft"
                  overlayStyle={{ maxWidth: "500px" }}
                >
                  {text}
                </a-tooltip>
              );
            },
          },
        ],
      },
    };
  },
  created() {
    if (
      (this.hasAction("edit-help") || this.hasAction("delete-help")) &&
      this.table.columns.length < 4
    ) {
      this.table.columns.push({
        title: "管理",
        width: "10em",
        dataIndex: "columns-manage",
        scopedSlots: {
          customRender: "dataManage",
        },
      });
    }
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.table.loading = true;
      getHelpList()
        .then((res) => {
          this.dataSource = res.data.list;
        })
        .finally(() => {
          setTimeout(() => {
            this.table.loading = false;
          }, 500);
        });
    },
    handleEdit(record = { id: -1 }) {
      this.$dialog(
        UpdateForm,
        {
          record,
          on: {
            ok: () => {
              this.fetchData();
            },
            cancel() {},
            close() {},
          },
        },
        {
          title: record.id === -1 ? "新增更新说明" : "修改更新说明",
          width: 700,
          height: 500,
          centered: true,
          maskClosable: false,
        }
      );
    },
    handleDelete(record) {
      deleteHelp({ id: record.id }).then((res) => {
        if (res.code === 0) {
          this.$message.success("删除成功");
          this.fetchData();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.list-box {
  background-color: white;
  padding: 20px;
  .input-container {
    text-align: right;
    .input-bar {
      width: 350px;
    }
  }
}
</style>
